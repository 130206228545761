var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "authentication-layout",
    [
      _c("template", { slot: "form-content" }, [_c("register-user-form")], 1),
      _c(
        "template",
        { slot: "navigation" },
        [
          _c(
            "v-btn",
            {
              staticClass: "link",
              attrs: { flat: "", small: "", color: "primary", to: "/login" },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-account-circle"),
              ]),
              _c("span", { staticClass: "text" }, [_vm._v("Voltar ao login")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }