<template>
  <authentication-layout>
    <template slot="form-content">
      <register-user-form />
    </template>

    <template slot="navigation">
      <v-btn flat small color="primary" class="link" :to="'/login'">
        <v-icon left>mdi-account-circle</v-icon>
        <span class="text">Voltar ao login</span>
      </v-btn>
    </template>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
import RegisterUserForm from '@/components/auth/RegisterUserForm.vue';

export default {
  name: 'Register',
  data() {
    return {};
  },
  components: {
    AuthenticationLayout,
    RegisterUserForm,
  },
};
</script>

<style lang="scss">
.header {
  > .title {
    font-family: 'Nexa', sans-serif !important;
    font-weight: 400;
    font-size: 1.125rem !important;
    color: #396753;
  }
}
</style>
